* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

header {
    padding: 0 2rem;
    min-height: 4rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #ffffff; /* Better background color */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

.logo {
    height: 60px; /* Adjust logo height for balance */
    width: auto; /* Maintain aspect ratio */
}

.header .web-name {
    font-size: 1.2rem;
    display: flex;
    align-items: center;
}

.navbar {
    display: flex;
    align-items: center;
}

.navbar-list {
    display: flex;
    gap: 2rem; /* Adjust gap for better spacing */
    list-style: none;
    align-items: center;
    margin-top: 0;
}

.navbar-link {
    text-transform: uppercase;
    text-decoration: none;
    color: #333333; /* Darker color for better contrast */
    font-size: 1rem;
    font-weight: 600; /* Slightly bolder text */
    transition: color 0.3s, border-bottom 0.3s;
    text-shadow: 1px 1px 2px rgba(0, 0, 1.5, 0.2); 
}

.navbar-link:hover,
.navbar-link:active {
    color: #007BFF; /* Color change on hover for better interaction */
    border-bottom: 2px solid #007BFF; /* Underline effect on hover */
}

.mobile-navbar-btn {
    display: none;
    background: transparent;
    cursor: pointer;
    z-index: 999;
}

.mobile-nav-icon {
    font-size: 1.5rem; /* Adjust icon size */
    color: #333333; /* Match text color */
}

@keyframes jump {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px); /* Adjust this value to control the jump height */
    }
    100% {
      transform: translateY(0);
    }
}
  
.jumping-icon {
    animation: jump 1s ease-in-out infinite;
}

@media screen and (max-width: 62em) {
    .mobile-navbar {
        width: 100%;
        background: #ffffff;
        position: absolute;
        top: 4rem;
        right: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        z-index: 999;
        padding: 1rem 0;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Add shadow to the mobile menu */
    }

    .mobile-navbar-list {
        flex-direction: column;
        align-items: center;
        display: flex;
        list-style: none;
        padding: 0;
    }

    .mobile-navbar-btn {
        display: inline-block;
        color: #333333;
    }

    .header .web-name {
        font-size: 1rem;
    }

    .navbar-list {
        display: none;
    }
}
